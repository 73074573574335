<template>
<div>
<resumen-dominio
    class=""
    mensaje=""
    v-bind:response="response"
    @addToList="addToList"
    />
    </div>

</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
export default {
  created : async  function (){
    if (this.posicion === "none")
      this.posicion = "";
    else if(this.posicion === undefined)
      this.posicion = "top";
    this.registerLabel = (this.registerLabel === undefined) ? 'Agregar'  : 'Registrar';

  },
  components:{
       /* vueHeadful,*/
        ResumenDominio: () => import ('@/components/DominioResumenComponent')
     },
  computed: {
    ...mapGetters([
        'active',
        'getdomain',
        'getzone',
        'getzoneid'
    ]),
        domainToFind : {
           get () {
               return this.$store.state.domain;
           } 
        },
        valordominio : function(){
             return (this.disponibilidadDominios.simbolo) + ''+(this.disponibilidadDominios.precio).toFixed(2) + '/año';
        }
    },
    watch: {
        domainToFind(newValue) {
            this.dominioModel.dominio = newValue;
            this.availabledomains(newValue);
        }
    },
    data: () => ({
        title:'Buscar Dominio',
        dominioModel  : {
            dominio : '',
            zona : '',
            idZona : 0
        },
      err: false,
      errorMsg:'',
        response : 0,
        dominio : {
            dominio : '',
            zona : '',
            idZona : 0,
            precio : 0,
            simbolo : ''
        },
        disponibilidadDominios : {

        },
        dominiosSugeridos : [

        ],
            tokenOP : '',
      items:[
          {
              'text':'dominio.com.ni', 
               icon:'mdi-checkbox-marked-circle',
              'subtitle':'$50.00/año',
              'color':'#7CC609'
          }
      ],
    }),
    methods: {
      addToList(newDomain) {
        this.$emit('addToList', newDomain);
      },
      toasterMessageResponse : function (msg, type){
        Vue.$toast.open({
          message: msg,
          type: type,
        });
      },
      compradominiostore : function(){
          localStorage.setItem(btoa('dominio'), btoa(JSON.stringify(this.disponibilidadDominios)));
          /*location.href ="/compradominio";*/
      },
      selectedDomain : function(domain,currency, amount){
          this.$store.commit('domainToPurchase', domain);
          this.$store.commit('domainCurrency', currency);
          this.$store.commit('domainPrice', amount);
          /*location.href ="/compradominio";*/
      },
      valordominiosugerido : function(moneda, valor){
              return ((moneda == 'Dólar') ? '$' : 'C$') + ''+(valor).toFixed(2) + '/año';
      },
      availabledomains : async function() {
        console.log('entro aca.')
      this.registerLabel = (this.registerLabel === undefined) ? 'Agregar'  : 'Registrar';

      await this.$http.get(this.$keys('DISPONIBILIDAD_DOMINIO') +'?dominio='+this.dominioModel.dominio+'&zona=' + this.getzone+'&idZona='+this.getzoneid).
        then((response) => {
        if (response.status === 200 ) {
          let array = []
          localStorage.setItem('localdomain', array);
          this.err = false;
            if(response.data.disponibilidadDominios){
              this.response  = 500;
                this.disponibilidadDominios =  response.data.disponibilidadDominios[0];
                this.dominiosSugeridos = response.data.disponibilidadDominios.filter( x => x.sugerido === true);
                this.tokenOP = response.data.tokenOP;
                this.addDomainToList(this.disponibilidadDominios);
            }
        }
      }).catch(error => {
        console.log('entro aca')
        if (error.response == 409){
          this.err = true;
          this.toasterMessageResponse(error.response.data.mensaje, 'error');
        }
      });
      }
    }
}
</script>
<style>
.top{
  margin-top: -130px !important;
}
@media (max-width: 725px){
  .top{
  margin-top: -190px !important;
}
}
.v-chip:before {
    background-color: none !important;
}
.font{
    font-size: 26px;
    font-weight: 600;
    color: #616161 !important;
}
.precio{
    line-height: 58px;
    font-size: 20px;
}
.parrafo1{
    color: #616161 !important;
    margin-right: 10px !important;
}
.decoracion{
   text-decoration: none;
}
@media (max-width: 725px){
    .mobile{
        margin-left: 10px !important;
    }
}
.text-negro{
    color: #000 !important;
    font-size: 20px !important;
}

</style>
